import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

export default function handleFeedbackMessages(response) {
  const localPath = "feedbackMessages";
  if (response?.data?.success) {
    // eslint-disable-next-line no-param-reassign
    response.data.successMessage = localize(localPath, `${response.data.success.module}.success.${response.data.success.key}`, {
      param: response.data.success.param || null
    });
  }

  if (response?.data?.caution) {
    // eslint-disable-next-line no-param-reassign
    response.data.cautionMessage = localize(localPath, `${response.data.caution.module}.caution.${response.data.caution.key}`, {
      param: response.data.caution.param || null
    });
  }

  if (response?.response?.data?.error) {
    // eslint-disable-next-line no-param-reassign
    response.response.data.errorMessage = localize(localPath, `${response.response.data.error.module}.errors.${response.response.data.error.key}`, {
      param: response.response.data.error.param || null
    });
  } else if (response?.data?.error) {
    // eslint-disable-next-line no-param-reassign
    response.data.errorMessage = localize(localPath, `${response.data.error.module}.errors.${response.data.error.key}`, {
      param: response.data.error.param || null
    });
  }
}
