const get = require("lodash/get");

const isPilot = require("@common/helpers/isPilot");

const flags = require(`@common/services/features/flags.${__PLATFORM__}`);
const featureTypes = require("@common/constants/featureTypes");

const pilotLiquidOxygen = require("@common/constants/pilots/pilotLiquidOxygen");

/*
This file exists both in client and server because we need to have acces to the platform constant (client : __PLATFORM__ | server : process.env.PLATFORM)

We can't mutualized this file because neither of those variable are accessible in the common folder
*/

module.exports = {
  isAddProductByScanEnabled() {
    return get(flags, featureTypes.addProductByScan);
  },
  isAddProductManuallyEnabled() {
    return get(flags, featureTypes.addProductManually);
  },
  isAuthBanEnabled() {
    return get(flags, featureTypes.authBan);
  },
  isBaseDocumentaireEnabled() {
    return get(flags, featureTypes.baseDocumentaire);
  },
  isCommunityEntityInterventionsEnabled() {
    return get(flags, featureTypes.communityEntityInterventions);
  },
  isCommunityTourEnabled() {
    return get(flags, featureTypes.communityTour);
  },
  isDeliveryNoteEnabled() {
    return get(flags, featureTypes.deliveryNote);
  },
  isFastpayEnabled() {
    return get(flags, featureTypes.fastpay);
  },
  isFaxesEnabled() {
    return get(flags, featureTypes.faxes);
  },
  isInterventionsEnabled() {
    return get(flags, featureTypes.interventions);
  },
  isInterventionReportEnabled() {
    return get(flags, featureTypes.interventionReport);
  },
  isMaintenanceEnabled() {
    return get(flags, featureTypes.maintenance);
  },
  isMessagingEnabled() {
    return get(flags, featureTypes.messaging);
  },
  isOfflinePdfEnabled() {
    return get(flags, featureTypes.offlinePdf);
  },
  isOrdoclicEnabled() {
    return get(flags, featureTypes.ordoclic);
  },
  isPatientsOrientedConversationsEnabled() {
    return get(flags, featureTypes.patientsOrientedMessages);
  },
  isPatientSignatureEnabled() {
    return get(flags, featureTypes.patientSignature);
  },
  isPlanificationActionButtonsEnabled() {
    return get(flags, featureTypes.planificationActionButtons);
  },
  isPatientTelesuiviSynchroButtonEnabled() {
    return get(flags, featureTypes.patientTelesuiviSynchroButton);
  },
  isPlanificationGlobalEnabled() {
    return get(flags, featureTypes.planificationGlobal);
  },
  isPlanificationOnContractCreationEnabled() {
    return get(flags, featureTypes.planificationOnContractCreation);
  },
  isPlanificationOnInterventionAchievedEnabled() {
    return get(flags, featureTypes.planificationOnInterventionAchieved);
  },
  isProductOriginEnabled() {
    return get(flags, featureTypes.productOrigin);
  },
  isQuestionnairesEnabled() {
    return get(flags, featureTypes.questionnaires);
  },
  isRemindersEnabled() {
    return get(flags, featureTypes.reminders);
  },
  isRiskAnalysisEnabled() {
    return get(flags, featureTypes.riskAnalysis);
  },
  isSelectMessageTypeOnExtranetEnabled() {
    return get(flags, featureTypes.selectMessageTypeOnExtranet);
  },
  isSleepStudiesEnabled() {
    return get(flags, featureTypes.sleepStudies);
  },
  isSmsEnabled() {
    return get(flags, featureTypes.sms);
  },
  isStockHandlerEnabled() {
    return get(flags, featureTypes.stockHandler);
  },
  isSupportEnabled() {
    return get(flags, featureTypes.support);
  },
  isSynchroPatientEnabled() {
    return get(flags, featureTypes.synchroPatient);
  },
  isSynchroStocksEnabled() {
    return get(flags, featureTypes.synchroStocks);
  },
  isTelesuiviAlertsEnabled() {
    return get(flags, featureTypes.telesuiviAlerts);
  },
  isWoocommerceEnabled() {
    return get(flags, featureTypes.woocommerce);
  },
  isOptitimeEnabled() {
    return get(flags, featureTypes.optitime);
  },
  isLiquidOxygenEnabled(options = {}) {
    if (get(flags, featureTypes.liquidOxygen)) {
      return isPilot(options, pilotLiquidOxygen);
    }

    return false;
  }
};
