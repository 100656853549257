const verifyIsAdministrateur = require("./verifyIsAdministrateur");
const verifyIsCelluleTelesuivi = require("./verifyIsCelluleTelesuivi");
const verifyIsCommercial = require("./verifyIsCommercial");
const verifyIsIntervenant = require("./verifyIsIntervenant");
const verifyIsATO2 = require("./verifyIsATO2");
const verifyIsReferentTechnique = require("./verifyIsReferentTechnique");
const verifyIsResponsableLogistique = require("./verifyIsResponsableLogistique");
const verifyIsResponsablePole = require("./verifyIsResponsablePole");
const verifyIsSecretaireFacturation = require("./verifyIsSecretaireFacturation");
const verifyIsRcoSecretary = require("./verifyIsRcoSecretary");
const verifyIsSecretairePlanification = require("./verifyIsSecretairePlanification");
const verifyIsSecretairePole = require("./verifyIsSecretairePole");
const verifyIsWebmaster = require("./verifyIsWebmaster");
const verifyIsPrescripteur = require("./verifyIsPrescripteur");
const verifyIsPatient = require("./verifyIsPatient");
const verifyIsSuperAdministrateur = require("./verifyIsSuperAdministrateur");
const verifyIsPharmacien = require("./verifyIsPharmacien");
const verifyIsAstreinte = require("./verifyIsAstreinte");
const verifyIsRegroupementPrescripteurs = require("./verifyIsRegroupementPrescripteurs");
const verifyIsHealthCareProvider = require("./verifyIsHealthCareProvider");
const verifyIsClinicalAssistant = require("./verifyIsClinicalAssistant");

module.exports = {
  verifyIsSuperAdministrateur,
  verifyIsAdministrateur,
  verifyIsCelluleTelesuivi,
  verifyIsCommercial,
  verifyIsIntervenant,
  verifyIsATO2,
  verifyIsReferentTechnique,
  verifyIsResponsableLogistique,
  verifyIsResponsablePole,
  verifyIsSecretaireFacturation,
  verifyIsRcoSecretary,
  verifyIsSecretairePlanification,
  verifyIsSecretairePole,
  verifyIsWebmaster,
  verifyIsPrescripteur,
  verifyIsPatient,
  verifyIsPharmacien,
  verifyIsAstreinte,
  verifyIsRegroupementPrescripteurs,
  verifyIsHealthCareProvider,
  verifyIsClinicalAssistant
};
