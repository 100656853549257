const isEmpty = require("lodash/isEmpty");

/**
 * This helpers is used to define if a feature can be used depending on the context poles / channels.
 *
 * @param {Object} options Object of 2 arrays poles & channels from the context.
 * @param {Object} pilot Object of 2 arrays poles & channels from folder 'common/constants/pilots'.
 * @returns {Boolean}
 */
const isPilot = (options = {}, pilot = {}) => {
  // If there is no pilot defined, then the feature is available
  if (isEmpty(pilot)) {
    return true;
  }

  // If there is no options, the feature can not be access
  if (isEmpty(options)) {
    return false;
  }

  if (pilot.pilotPoles === undefined) {
    console.error("[common/helpers][isPilot] Current pilot haven't constants poles defined.");
    return false;
  }

  if (pilot.pilotChannels === undefined) {
    console.error("[common/helpers][isPilot] Current pilot haven't constants channels defined.");
    return false;
  }

  const hasPilotPoles = options.poleIds ? pilot.pilotPoles.some(pilotPole => options.poleIds.includes(pilotPole)) : false;
  const hasPilotChannels = options.channelIds ? pilot.pilotChannels.some(pilotChannel => options.channelIds.includes(pilotChannel)) : false;

  return hasPilotChannels || hasPilotPoles;
};

module.exports = isPilot;
