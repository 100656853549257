import { componentNameResolver } from "@common/helpers/settings/overrideResolver";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.prescriber.patients";

const settings = require(`@common/settings/${__PLATFORM__}`);

// Patient View Segments
const PatientViewInformations = () =>
  import(/* webpackChunkName: "patient-view" */ "@/modules/patient/view/_components/informations/PatientViewInformations");
const PatientViewRespiratory = () => import(/* webpackChunkName: "patient-view" */ "@/modules/patient/view/_components/PatientViewRespiratory");
const PatientViewMonitoring = () => import(/* webpackChunkName: "patient-view" */ "@/modules/patient/view/_components/PatientViewMonitoring");

const Patients = () => import(/* webpackChunkName: "patient-view" */ "@/modules/patients/PatientsListPrescriber");
const PatientsToRenew = () => import(/* webpackChunkName: "patient-view" */ "@/modules/patients/_components/PatientsToRenew");

// Patient Lift Form
const PatientLiftFormView = () => import(/* webpackChunkName: "patient-lift-form-view" */ "@/modules/patient-lift-form/view");

const {
  patientsRights: { PATIENT_VIEW_MONITORING }
} = require("@common/services/acm/rights");

export default [
  {
    path: "patients",
    name: "patients",
    redirect: "patients/respiratory"
  },
  {
    path: "patients/respiratory",
    name: "patientsList",
    component: Patients,
    props: true,
    meta: {
      title: localize(localPath, `title--patientsList`)
    }
  },
  {
    path: "patients/renewals",
    alias: "patients/renouvellements",
    name: "patientsToRenewList",
    component: PatientsToRenew,
    props: true,
    meta: {
      title: localize(localPath, `title--patientsList`)
    }
  },
  {
    path: "patient/lift-form/:phone",
    alias: "patient/levee-fiche/:phone",
    name: "patientLiftFormView",
    component: PatientLiftFormView,
    meta: {
      title: localize(localPath, `title--patientView`)
    }
  },
  {
    path: "patient/:id",
    component: () => {
      const component = componentNameResolver("PatientView", settings.overridedModules);
      return import(`@/modules/patient/view/${component}`);
    },
    children: [
      {
        path: "",
        name: "patientView",
        meta: {
          title: localize(localPath, `title--patientView`)
        },
        redirect: to => {
          return { name: "patientViewMonitoring", params: to.params };
        }
      },
      {
        path: "info",
        alias: "informations",
        name: "patientViewInformations",
        meta: {
          title: localize(localPath, `title--patientView`)
        },
        component: PatientViewInformations
      },
      {
        path: "respiratory",
        alias: "respiratoire",
        name: "patientViewRespiratoire",
        props: true,
        meta: {
          title: localize(localPath, `title--patientView`)
        },
        component: PatientViewRespiratory
      },
      {
        path: "monitoring",
        name: "patientViewMonitoring",
        meta: {
          title: localize(localPath, `title--patientView`),
          rights: [PATIENT_VIEW_MONITORING]
        },
        component: PatientViewMonitoring
      },
      {
        path: "questionnaires",
        alias: "questionnaires",
        name: "patientViewQuestionnaires",
        meta: {
          title: localize(localPath, `title--patientView`)
        },
        component: () => {
          const component = componentNameResolver("TabsPatientViewQuestionnaires", settings.overridedModules);
          return import(`@/modules/patient/view/_components/questionnaires/${component}`);
        }
      }
    ]
  }
];
