const roleHelpers = require("../../helpers/roles");
/**
 *
 * @param {Array} roleIds
 * @param {Array} roleIds
 * @param {Array} roles
 * @param {Array} rights
 * mapRightsMachineLabel takes roleIds from user to map them to rights
 *
 * Expected output format : ["see_prescribers", "edit_prescribers"]
 */
const mapRightsMachineLabel = (roleIds, roles, rights) => {
  const userRoles = roleIds.map(roleId => roles.find(role => role._id === roleId));
  const userRightIds = userRoles.reduce((acc, val) => {
    acc.push(...val.rightIds);
    return acc;
  }, []);

  const userRights = userRightIds
    .map(id => rights.find(right => right._id === id))
    .filter(right => right)
    .map(right => right._id);
  return userRights;
};

const getUserRolesChecks = user => {
  return {
    isAdministrateur: roleHelpers.verifyIsAdministrateur(user),
    isCelluleTelesuivi: roleHelpers.verifyIsCelluleTelesuivi(user),
    isCommercial: roleHelpers.verifyIsCommercial(user),
    isIntervenant: roleHelpers.verifyIsIntervenant(user),
    isATO2: roleHelpers.verifyIsATO2(user),
    isReferentTechnique: roleHelpers.verifyIsReferentTechnique(user),
    isResponsableLogistique: roleHelpers.verifyIsResponsableLogistique(user),
    isResponsablePole: roleHelpers.verifyIsResponsablePole(user),
    isSecretaireFacturation: roleHelpers.verifyIsSecretaireFacturation(user),
    isRcoSecretary: roleHelpers.verifyIsRcoSecretary(user),
    isSecretairePlanification: roleHelpers.verifyIsSecretairePlanification(user),
    isSecretairePole: roleHelpers.verifyIsSecretairePole(user),
    isWebmaster: roleHelpers.verifyIsWebmaster(user),
    isPrescripteur: roleHelpers.verifyIsPrescripteur(user),
    isSuperAdministrateur: roleHelpers.verifyIsSuperAdministrateur(user),
    isPatient: roleHelpers.verifyIsPatient(user),
    isPharmacien: roleHelpers.verifyIsPharmacien(user),
    isAstreinte: roleHelpers.verifyIsAstreinte(user),
    isRegroupementPrescripteurs: roleHelpers.verifyIsRegroupementPrescripteurs(user),
    isHealthCareProvider: roleHelpers.verifyIsHealthCareProvider(user),
    isClinicalAssistant: roleHelpers.verifyIsClinicalAssistant(user)
  };
};

/**
 *
 * @param {Object} user
 * @param {Array} roles
 * @param {Array} rights
 * acm creates a can method to check user permissions
 * Usage :
 *
 * Object.assign(user, acm(user));
 */
const acm = user => {
  return {
    /**
     * @param {String} action
     * @returns {Boolean} bool
     * can method returns true if user has the right to perform the action
     *
     * user.can("see_telesuivi")
     */
    can(action) {
      let isAllowed = false;

      const { rights } = user;

      if (rights.includes(action)) {
        isAllowed = true;
      }
      return isAllowed;
    },

    /**
     * @param {Array} actions
     * @returns {Boolean} bool
     * canEvery method returns true if user has the right to perform every actions
     *
     * user.canEvery(["see_telesuivi", "edit_users"])
     */
    canEvery(actions) {
      let isAllowed = false;

      if (actions.every(action => this.can(action))) {
        isAllowed = true;
      }

      return isAllowed;
    },

    /**
     * @param {Array} actions
     * @returns {Boolean} bool
     * canSome method returns true if user has the right to perform at least one of the actions
     *
     * user.canSome(["edit_prescribers", "see_prescribers"])
     */
    canSome(actions) {
      let isAllowed = false;

      if (actions.some(action => this.can(action))) {
        isAllowed = true;
      }

      return isAllowed;
    }
  };
};

module.exports = { mapRightsMachineLabel, acm, getUserRolesChecks };
