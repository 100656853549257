module.exports = {
  //  COMMON
  SUPER_ADMINISTRATEUR: {
    id: "super_administrateur"
  },
  ADMINISTRATEUR: {
    id: "administrateur"
  },
  CELLULE_TELESUIVI_1: {
    id: "cellule_telesuivi_1"
  },
  COMMERCIAL: {
    id: "commercial"
  },
  DIRECTEUR_COMMERCIAL: {
    id: "directeur_commercial"
  },
  INTERVENANT: {
    id: "intervenant"
  },
  RESPONSABLE_LOGISTIQUE: {
    id: "responsable_logistique"
  },
  PATIENT: {
    id: "patient",
    label: "Patient"
  },
  PRESCRIPTEUR: {
    id: "prescripteur"
  },
  RESPONSABLE_POLE: {
    id: "responsable_pole"
  },
  SECRETAIRE_FACTURATION: {
    id: "secretaire_facturation"
  },
  SECRETAIRE_RCO: {
    id: "secretaire_rco"
  },
  SECRETAIRE_PLANIFICATION: {
    id: "secretaire_planification"
  },
  SECRETAIRE_POLE: {
    id: "secretaire_pole"
  },
  WEBMASTER: {
    id: "webmaster"
  },
  AT_O2: {
    id: "at_o2"
  },
  REFERENT_TECHNIQUE: {
    id: "referent_technique"
  },
  PHARMACIEN: {
    id: "pharmacien"
  },
  ASTREINTE: {
    id: "astreinte"
  },
  SUPPORT: {
    id: "support"
  },
  REGROUPEMENT_MEDECINS: {
    id: "regroupement_medecins"
  },

  //  INTUS
  SLEEP_TECHNICIANS: {
    id: "sleep_technicians"
  },
  CUSTOMER_SERVICES: {
    id: "customer_services"
  },
  CLINICAL_TEAM: {
    id: "clinical_team"
  },

  //  MEDPRO
  BILLING_MANAGER: {
    id: "billing_manager"
  },
  HEALTH_CARE_PROVIDER: {
    id: "health_care_provider"
  },
  CLINICAL_ASSISTANT: {
    id: "clinical_assistant"
  },
  RECEPTION: {
    id: "reception"
  },
  PURCHASER: {
    id: "purchaser"
  }
};
