module.exports = {
  pilotPoles: [
    "RT01", // Midi-Pyrénées
    "PR06" // Aquitaine
  ],
  pilotChannels: [
    // Midi-Pyrénées
    "RT",
    // Aquitaine
    "BX",
    "AG",
    "PX",
    "LG"
  ]
};
