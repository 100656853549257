import {
  searchDelegates,
  searchProducts,
  searchDelegatesToAssign,
  searchUsers,
  searchPrescriptionAddresses,
  searchCommunities,
  searchIntervenants,
  searchContacts,
  searchCommunitiesGroups,
  searchPatients,
  searchLocations,
  searchCities
} from "@/modules/common/_api";

const entitlements = require("@common/constants/entitlements");
const { COMMERCIAL, DIRECTEUR_COMMERCIAL, PHARMACIEN, AT_O2 } = require("@common/constants/roles");

const actions = {
  searchDispositifsMedicaux(context, input) {
    return new Promise((resolve, reject) => {
      searchProducts({
        query: { productType: "machine", active: true, input },
        skip: 0,
        limit: 50,
        source: "local"
      })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchConsommables(context, input) {
    return new Promise((resolve, reject) => {
      searchProducts({
        query: { productType: "consommable", active: true, input },
        skip: 0,
        limit: 50,
        source: "local"
      })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchSamples(context, input) {
    return new Promise((resolve, reject) => {
      searchProducts({
        query: { productType: "consommable", active: true, withoutAgaProducts: true, input },
        skip: 0,
        limit: 50,
        source: "local"
      })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchUsers(context, input) {
    return searchUsers({
      query: { input, active: true },
      skip: 0,
      limit: 10,
      source: "local"
    });
  },
  searchIntervenants(context, input) {
    return searchIntervenants({ query: { input, active: true }, skip: 0, limit: 10, source: "local" });
  },
  searchPrescriptionAddressesLocally(context, input) {
    return searchPrescriptionAddresses({ query: { input, active: true }, skip: 0, limit: 20, source: "local" });
  },
  searchPrescriptionAddressesRemotely(context, input) {
    return searchPrescriptionAddresses({ query: { input, active: true }, skip: 0, limit: 40, source: "remote" });
  },
  searchContactEstablishment(context, input) {
    return searchContacts({ query: { typeId: "establishment", input }, skip: 0, limit: 10, source: "local" });
  },
  searchContactCityPharmacy(context, input) {
    return searchContacts({ query: { typeId: "cityPharmacy", input }, skip: 0, limit: 10, source: "local" });
  },
  searchContactLaboratory(context, input) {
    return searchContacts({ query: { typeId: "laboratory", input }, skip: 0, limit: 10, source: "local" });
  },
  searchContactIdel(context, input) {
    return searchContacts({ query: { typeId: "idel", input }, skip: 0, limit: 10, source: "local" });
  },
  searchContactHealthExecutive(context, input) {
    return searchContacts({ query: { typeId: "healthExecutive", input }, skip: 0, limit: 10, source: "local" });
  },
  searchContactServiceDietician(context, input) {
    return searchContacts({ query: { typeId: "serviceDietician", input }, skip: 0, limit: 10, source: "local" });
  },
  searchContactService(context, input) {
    return searchContacts({ query: { typeId: "service", input }, skip: 0, limit: 10, source: "local" });
  },
  searchContactPersonalPhysician(context, input) {
    return searchContacts({ query: { typeId: "personalPhysician", input }, skip: 0, limit: 10, source: "local" });
  },
  searchContactOther(context, input) {
    return searchContacts({ query: { typeId: "other", input }, skip: 0, limit: 10, source: "local" });
  },
  searchContactPrescriber(context, input) {
    return searchContacts({ query: { typeId: "prescriber", input }, skip: 0, limit: 10, source: "local" });
  },
  searchDelegates(context, input) {
    return searchDelegates({ query: { input }, skip: 0, limit: 10 });
  },
  searchProducts(context, input) {
    return searchProducts({ query: { input }, skip: 0, limit: 10 });
  },
  searchATO2(context, input) {
    return searchUsers({
      query: { input, active: true, roleIds: [AT_O2.id] },
      skip: 0,
      limit: 10,
      source: "local"
    });
  },
  searchDelegatesToAssign(context, input) {
    return searchDelegatesToAssign({ query: { input, active: true, roleIds: [COMMERCIAL.id, DIRECTEUR_COMMERCIAL.id] }, skip: 0, limit: 10 });
  },
  searchUsersThatCanBeLinkedToIntervention(context, input) {
    return searchUsers({
      query: {
        input,
        active: true,
        entitlementIds: [entitlements.realiseInterventions]
      },
      skip: 0,
      limit: 10
    });
  },
  searchPharmacistsThatCanBeLinkedToIntervention(context, input) {
    return searchUsers({
      query: {
        input,
        active: true,
        entitlementIds: [entitlements.realiseInterventions],
        roleIds: [PHARMACIEN.id]
      },
      skip: 0,
      limit: 10
    });
  },
  searchPharmacistsWithExternalId(context, input) {
    return searchUsers({
      query: {
        input,
        active: true,
        withExternalId: true,
        roleIds: [PHARMACIEN.id]
      },
      skip: 0,
      limit: 10
    });
  },
  searchCommunitiesLocally(context, input) {
    return searchCommunities({ query: { input, activeStatus: ["active"] }, skip: 0, limit: 10, source: "local" });
  },
  searchCommunitiesRemotely(context, input) {
    return searchCommunities({ query: { input, activeStatus: ["active"] }, skip: 0, limit: 10, source: "remote" });
  },
  searchCommunitiesGroups(context, input) {
    return searchCommunitiesGroups({ query: { input }, skip: 0, limit: 10, source: "local" });
  },
  searchPatients(context, input) {
    return searchPatients({ query: { input }, skip: 0, limit: 10, source: "local" });
  },
  searchLocations(context, query) {
    return searchLocations({ query });
  },
  searchCities(context, query) {
    return searchCities({ query });
  }
};

export default { actions, namespaced: true };
