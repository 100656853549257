const liquidOxygenProducts = require("../../constants/liquidOxygen/liquidOxygenProducts");

/**
 *
 * @param {String} productId
 * @returns {Boolean}
 */
const isLiquidOxygenProduct = productId => {
  if (!productId) {
    return false;
  }

  return liquidOxygenProducts.includes(productId);
};

module.exports = isLiquidOxygenProduct;
