/* eslint-disable import/no-cycle */

import Vue from "vue";
import Vuex from "vuex";

// Modules
import caisses from "@/modules/caisses/_store";
import versionManager from "@/services/versionManager/_store";
import ordoclic from "@/services/ordoclic/_store";
import appInfos from "@/services/appInfos/_store";
import supportTicketsAdd from "@/modules/support-tickets/add/_store";
import credentials from "@/modules/credentials/_store";
import login from "@/modules/login/_store/index.prescriber";
import patientLiftFormView from "@/modules/patient-lift-form/view/_store";
import patients from "@/modules/patients/_store";
import patientView from "@/modules/patient/view/_store";
import conversationsList from "@/modules/conversations/list/_store";
import conversationsAdd from "@/modules/conversations/add/_store/index.prescriber";
import conversationsView from "@/modules/conversations/view/_store";
import aald from "@/modules/aald/_store";
import oald from "@/modules/oald/_store";
import dashboard from "@/modules/dashboard/_store/index.prescriber";

import common from "@/modules/common/_store";
import list from "@/mixins/list/_store";

import exports from "@/modules/exports/get-export/_store";
import publicationsView from "@/modules/publications/view/_store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    caisses,
    credentials,
    login,
    versionManager,
    ordoclic,
    appInfos,
    supportTicketsAdd,
    patients,
    patientLiftFormView,
    patientView,
    conversationsList,
    conversationsAdd,
    conversationsView,
    oald,
    aald,
    dashboard,
    common,
    list,
    exports,
    publicationsView
  }
});
